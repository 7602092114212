<template>
<div>
    <vs-sidebar parent="comments" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>View History <b>({{regPlate}})</b></h4>
                </div>
                <feather-icon icon="XIcon" @click.stop="closeWindow" class="cursor-pointer"></feather-icon>
            </div>
            <vs-divider class="mb-0" />
            <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
                <div class="vx-col w-full p-base">
                    <ul class="vx-timeline comment-timeline">
                      <li v-for="(item, index ) in data" :key="index">
                        <div class="timeline-icon" :class="`bg-warning`">
                          <feather-icon icon="MailIcon" svgClasses="w-5 h-5" />
                        </div>
                        <div class="timeline-info">
                          <p class="font-semibold">{{ item.tuple2 }}</p>
                          <span class="activity-desc">{{ item.modifiedByName }}</span>
                        </div>
                        <small class="text-grey activity-e-time">{{ item.createdDate | formatLongDateAgo  }}</small>
                      </li>
                    </ul>
                </div>
            </component>
        </div>
    </vs-sidebar>
</div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    props: {
        openSidebar: {
            type: Boolean,
            required: true,
            default: false
        },
        data: {
          type: Array,
          required: true
        },
        regPlate: {
          type: String,
          default: () => ""
        }
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },
        };
    },
    components: {
        VuePerfectScrollbar
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag
        }
    },

    watch: {
        openSidebar: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
    },
    methods: {
        closeWindow() {
            this.$emit("onClose");
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
#comments {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);

        @media screen and (max-width: 1200px) {
            min-width: 100vw;
            margin: 0px;
            max-height: 100vh;
        }

    }
}

.scroll-area--nofications-dropdown {
    max-height: 100rem;
}
</style>
